import React from "react"
import BlogPostPreviewHorizontal from "./blog-post-preview-horizontal"
import BlogMultiplePostPreview from "./blog-multiple-post-preview"

class BlogMultiplePostPreviewWithFeaturedPost extends React.Component {
  render() {
    const {
      blogPosts,
      headerText,
      className,
      showAllPosts = false,
    } = this.props

    // Check if there are blog posts available

    // Split the first post and the next three posts
    const prominentPost = blogPosts[0]
    const otherPosts = showAllPosts ? blogPosts.slice(1) : blogPosts.slice(1, 4) // This will get posts 2 to 4

    return (
      <div
        className={`editors-pick-post-container d-flex flex-column ${className ??
          ""}`}
      >
        <h2 className="text-big blog-text-dark fw-bolder m-0">{headerText}</h2>
        <BlogPostPreviewHorizontal item={prominentPost} hideImage={false} />
        <BlogMultiplePostPreview items={otherPosts} itemsPerRow={3} />
      </div>
    )
  }
}

export default BlogMultiplePostPreviewWithFeaturedPost
