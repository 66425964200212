import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogAuthor from "../components/blog/blog-author"
import BlogMultiplePostPreviewWithFeaturedPost from "../components/blog/blog-multiple-post-preview-with-featured-post.js"
import BlogFooterCta from "../components/blog/blog-footer-cta"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import slugify from "../utils/slugify"
import { Link } from "gatsby"

class BlogAuthorTemplate extends React.Component {
  transformRichTextToHTML = (richTextDocument, imageReferences) => {
    const options = {
      renderNode: {
        // We find the relevant image info by associating the node.data.target.sys.id to the relavant image reference to get the url and to include it inside the HTML
        // as <img> tags.
        [BLOCKS.EMBEDDED_ASSET]: node => {
          let image = imageReferences.find(
            image => image.contentful_id === node.data.target.sys.id
          )
          return `<img 
          class="blog-post-content-image-container"
          src=${image.url} alt=${image.title}/>`
        },
      },
    }

    return documentToHtmlString(richTextDocument)
  }

  render() {
    const authorPageData = this.props.pageContext

    const authorPosts = this.props.data.allContentfulPostsWealthybites.nodes

    const responseDocumentContent = JSON.parse(authorPageData.description.raw)

    const {
      name,
      shortDescription,
      image,
      mail,
      linkedin,
      reddit,
      medium,
      twitter,
      metaTitle,
      metaImage,
      metaDescription,
    } = authorPageData
    const descriptionHTML = this.transformRichTextToHTML(
      responseDocumentContent
    )
    // const longDescription = pageContext.description.description
    return (
      <Layout
        activePage={"directory"}
        backgroundColor={"#fff"}
        headerBackgroundColor={"#11152E"}
      >
        <SEO
          description={metaDescription}
          title={metaTitle}
          image={metaImage.file.url}
          canonicalUrlPath={`/${slugify(name)}/`}
          isDynamicImage={true}
        />
        <div className="blog-font-family m-0 p-0">
          {/*Directory Header*/}
          <div
            style={{
              backgroundColor: "#11152E",
            }}
          >
            <div className="starry-pattern-background container space-top-2 space-bottom-1 d-flex flex-column gap-4">
              <div>
                <Link className="blog-breadcrump blog-text-gray" to={`/`}>
                  <span>Wealthybites</span>
                </Link>{" "}
                / <span>{name}</span>
              </div>
              <h2 className="blog-directory-title text-white m-0">{name}</h2>
            </div>
          </div>
          <div className="blog-font-family px-2 px-md-0">
            <div className="container space-top-2 space-bottom-2 mb-4 px-3 px-md-0">
              <BlogAuthor
                image={image.file.url}
                name={name}
                description={descriptionHTML}
                mail={mail}
                linkedin={linkedin}
                medium={medium}
                reddit={reddit}
                twitter={twitter}
                isAuthorPage={true}
              />
            </div>

            {/* Editor's pick */}
            {authorPosts.length && (
              <BlogMultiplePostPreviewWithFeaturedPost
                className="container space-bottom-2 mb-4"
                blogPosts={authorPosts}
                headerText="From the author"
                showAllPosts={true}
              />
            )}
          </div>
        </div>

        {/* astronaut cta */}
        <BlogFooterCta />
      </Layout>
    )
  }
}

export default BlogAuthorTemplate

// Get all contentful post where author equals this author
export const pageQuery = graphql`
  query($authorId: String!) {
    allContentfulPostsWealthybites(
      filter: { author: { id: { eq: $authorId } } }
      sort: { fields: [createdAt], order: DESC }
    ) {
      nodes {
        slug
        title
        metaDescription
        readingTime
        image {
          file {
            url
          }
        }
        category {
          name
        }
        insightsCategory {
          name
        }
        author {
          name
          image {
            file {
              url
            }
          }
        }
        date
      }
    }
  }
`
