import React from "react"
import BlogAuthorPreview from "./blog-author-preview"
import BlogCategoryTag from "./blog-category-tag"
import { Link } from "gatsby"
class BlogPostPreviewHorizontal extends React.Component {
  #navigateToArticle = blogPostItem => {
    window.location.href = `/${blogPostItem.category.name}/${blogPostItem.slug}`.toLowerCase()
  }
  render() {
    const { item, className } = this.props

    return (
      <div
        style={{ backgroundColor: "#D0D0DF" }}
        className="blog-font-family p-lg-7 p-3 p-md-5 blog-border-radious"
      >
        <div
          className={`row flex-column-reverse flex-lg-row align-items-center ${className ??
            ""}`}
        >
          <div
            id="content"
            className="d-flex flex-column gap-4 col-12 col-lg-8"
          >
            <div className="d-flex align-items-center">
              <div
                id="tag and reading time"
                className="d-flex gap-2 align-items-center me-3 text-medium"
              >
                <BlogCategoryTag name={item.category.name} />
                <span className="blog-text-dark">{item.readingTime} read</span>
              </div>
            </div>
            <a
              className="cursor-pointer no-underline-link"
              href={`/${item.category.name}/${item.slug}`.toLowerCase()}
            >
              <h5 className="fw-bolder blog-text-dark text-up-to-two-lines m-0 blog-post-preview-horizontal-header">
                {item.title}
              </h5>
            </a>
            <BlogAuthorPreview
              date={item.date}
              image={item.author.image.file.url}
              name={item.author.name}
              className="blog-text-dark"
            />
            <Link
              className="cta cta-button btn button-medium text-medium btn-primary bg-dark-blue"
              to={`/${item.category.name}/${item.slug}/`.toLowerCase()}
            >
              Read Post
            </Link>
          </div>
          <div
            className="cursor-pointer col-12 col-lg-4 col-lg-4"
            onClick={() => this.#navigateToArticle(item)}
          >
            <div className="blog-post-preview-image-container-horizontal">
              <img
                className="blog-post-preview-image"
                src={item.image.file.url}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BlogPostPreviewHorizontal
